import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: React.HTMLAttributes<HTMLImageElement>['className'];
  tier: string;
};

const TierIcon: React.FC<Props> = ({ className, tier }) => {
  const filename = React.useMemo(
    () => tier.toLowerCase().split(' ')[0],
    [tier]
  );

  return (
    <img
      src={
        filename === 'unranked'
          ? '//opgg-desktop-data.akamaized.net/download/duo/images/tiers/unranked.svg'
          : `//opgg-static.akamaized.net/images/medals_new/${filename}.png?image=q_auto,f_webp,w_80&v=1665988469243`
      }
      width="auto"
      height={24}
      className={classNames('', className)}
    />
  );
};

export default TierIcon;
