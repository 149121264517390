import { gql, useSubscription } from '@apollo/client';
import React from 'react';
import { CORE_CLASH_TEAM, CORE_CLASH_TEAM_MEMBER } from '../fragments/clash';
import { ClashTeamQueryVariables } from '../queries/clash';
import { ClashTeam } from '../types/lol/clash.type';
import { FetchActionType } from '../types/ReducerTypes';
import { Nullable } from '../types/UtilTypes';

export const SUBSCRIPTION_CLASH_TEAM = gql`
  subscription OnUpdatedClashTeam($region: String!) {
    clashTeamUpdated(region: $region) {
      action
      clash {
        ...CoreClashTeam
      }
    }
  }
  ${CORE_CLASH_TEAM}
`;

export const SUBSCRIPTION_CLASH_TEAM_MEMBER = gql`
  subscription OnUpdatedClashTeamMember($region: String!) {
    clashMemberUpdated(region: $region) {
      action
      member {
        ...CoreClashTeamMember
      }
    }
  }
  ${CORE_CLASH_TEAM_MEMBER}
`;

export type ClashTeamChangeSet = {
  action: FetchActionType;
  clash: ClashTeam;
};

export function useClashTeamUpdated(
  variables: ClashTeamQueryVariables
): Nullable<ClashTeamChangeSet> {
  const [changeSet, setChangeSet] =
    React.useState<Nullable<ClashTeamChangeSet>>(null);

  const { data } = useSubscription<{
    clashTeamUpdated: ClashTeamChangeSet;
  }>(SUBSCRIPTION_CLASH_TEAM, {
    variables,
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (!data || !data.clashTeamUpdated) return;

    setChangeSet(data.clashTeamUpdated);
  }, [data]);

  return changeSet;
}

export function useClashTeamMemberUpdated(
  variables: ClashTeamQueryVariables
): Nullable<ClashTeamChangeSet> {
  const [changeSet, setChangeSet] =
    React.useState<Nullable<ClashTeamChangeSet>>(null);

  const { data } = useSubscription<{
    clashTeamUpdated: ClashTeamChangeSet;
  }>(SUBSCRIPTION_CLASH_TEAM_MEMBER, {
    variables,
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (!data || !data.clashTeamUpdated) return;

    setChangeSet(data.clashTeamUpdated);
  }, [data]);

  return changeSet;
}
