import { gql, useMutation } from '@apollo/client';
import { CORE_CLASH_TEAM } from '../fragments/clash';
import {
  ClashTeam,
  ClashTeamFormState,
  ClashTier,
} from '../types/lol/clash.type';
import { Position } from '../types/lol/partner.type';

export const ADD_CLASH_TEAM = gql`
  mutation AddClashTeam(
    $content: String!
    $discordUrl: String
    $name: String!
    $region: String!
    $tier: Int
    $master: ClashMemberType!
    $members: [ClashMemberType!]!
  ) {
    addClashTeam(
      content: $content
      discordUrl: $discordUrl
      name: $name
      region: $region
      tier: $tier
      master: $master
      members: $members
    ) {
      ...CoreClashTeam
    }
  }
  ${CORE_CLASH_TEAM}
`;

export const DELETE_CLASH_TEAM = gql`
  mutation DeleteClashTeam($id: ID!) {
    deleteClashTeam(id: $id) {
      id
    }
  }
`;

export const UPDATE_CLASH_TEAM = gql`
  mutation UpdateClashTeam(
    $id: ID!
    $name: String!
    $content: String!
    $tier: Int
    $discordUrl: String
  ) {
    updateClashTeam(
      id: $id
      name: $name
      content: $content
      tier: $tier
      discordUrl: $discordUrl
    ) {
      ...CoreClashTeam
    }
  }
  ${CORE_CLASH_TEAM}
`;

export const BRING_UP_CLASH_TEAM = gql`
  mutation BringUpClashTeam($teamId: ID!) {
    refreshClashTeamUpdateTime(id: $teamId) {
      ...CoreClashTeam
    }
  }
  ${CORE_CLASH_TEAM}
`;

export const INVITE_TO_CLASH_TEAM = gql`
  mutation InviteToClashTeam($partnerId: ID!) {
    inviteClashTeam(partnerId: $partnerId)
  }
`;

export interface ClashTeamMemberParams {
  summonerName: string;
  gameName?: string;
  tagLine?: string;
  position: Position;
  tier: ClashTier;
}

export interface RegisterClashTeamParams extends ClashTeamFormState {
  master?: ClashTeamMemberParams;
  members?: ClashTeamMemberParams[];
}

export interface UpdateClashTeamParams extends ClashTeamFormState {
  id: string;
}

export function useAddClashTeam() {
  const [addClashTeam, { ...rest }] = useMutation<
    { addClashTeam: ClashTeam },
    RegisterClashTeamParams
  >(ADD_CLASH_TEAM);

  return {
    addClashTeam,
    ...rest,
  };
}

export function useDeleteClashTeam() {
  const [deleteClashTeam, { ...rest }] = useMutation<{
    deleteClashTeam: ClashTeam;
  }>(DELETE_CLASH_TEAM);

  return {
    deleteClashTeam,
    ...rest,
  };
}

export function useUpdateClashTeam() {
  const [updateClashTeam, { ...rest }] = useMutation<
    { updateClashTeam: ClashTeam },
    UpdateClashTeamParams
  >(UPDATE_CLASH_TEAM);

  return {
    updateClashTeam,
    ...rest,
  };
}

export function useBringUpClashTeam() {
  const [bringUpClashTeam, { ...rest }] = useMutation<
    { refreshClashTeamUpdateTime: ClashTeam },
    { teamId: number }
  >(BRING_UP_CLASH_TEAM);

  return {
    bringUpClashTeam,
    ...rest,
  };
}

export function useInviteToClashTeam() {
  const [invite, { ...rest }] = useMutation<
    { inviteClashTeam: boolean },
    { partnerId: number }
  >(INVITE_TO_CLASH_TEAM);

  return {
    invite,
    ...rest,
  };
}
