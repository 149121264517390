import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: React.HTMLAttributes<HTMLSpanElement>['className'];
  children: React.ReactNode;
};

const PartnerBadge: React.FC<Props> = ({ className, children }) => {
  return (
    <span
      className={classNames(
        'font-bold px-1 text-4xs tracking-tighter rounded whitespace-nowrap',
        className
      )}
    >
      {children}
    </span>
  );
};

export default PartnerBadge;
