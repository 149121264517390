import classNames from 'classnames';
import React from 'react';
import TierIcon from './TierIcon';

type Props = {
  className?: React.HTMLAttributes<HTMLSpanElement>['className'];
  tierShortened?: boolean;
  tier: string;
};

const isUnranked = (tier: string) => tier.toLowerCase() === 'unranked';

const convertRankString = (rankString: string): string => {
  const rankMapping: { [key: string]: string } = {
    Unranked: 'UR',
    Iron: 'I',
    Bronze: 'B',
    Silver: 'S',
    Gold: 'G',
    Platinum: 'P',
    Emerald: 'E',
    Diamond: 'D',
    Master: 'M',
    Grandmaster: 'GM',
    Challenger: 'C',
  };

  const rankSplit = rankString.split(' ');
  const rank = rankSplit[0];
  const rankNumber = rankSplit[1];

  if (rankMapping.hasOwnProperty(rank)) {
    const abbreviation = rankMapping[rank];
    return isUnranked(rankString) ? abbreviation : abbreviation + rankNumber;
  }

  return rankString;
};

const SummonerTier: React.FC<Props> = ({ tierShortened, className, tier }) => {
  return (
    <span
      className={classNames('flex items-center space-x-2 shrink-0', className)}
    >
      <span className="flex items-center">
        <span className="w-6 inline-flex justify-center mr-1 shrink-0">
          <TierIcon tier={tier} />
        </span>

        <span
          className={classNames('text-2xs whitespace-nowrap', {
            'text-gray-400': isUnranked(tier),
          })}
        >
          {tierShortened ? convertRankString(tier) : tier}
        </span>
      </span>
    </span>
  );
};

export default SummonerTier;
