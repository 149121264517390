import {
  ApolloError,
  ApolloQueryResult,
  gql,
  OperationVariables,
  useQuery,
} from '@apollo/client';
import dayjs from 'dayjs';
import { last } from 'lodash-es';
import React from 'react';
import { CORE_CLASH_TEAM, CORE_CLASH_TEAM_MEMBER } from '../fragments/clash';
import { useUser } from '../hooks/user';
import { useClashTeamUpdated } from '../subscriptions/clash';
import { FetchActionType } from '../types/ReducerTypes';
import { ClashTeam, ClashTeamMember } from '../types/lol/clash.type';
import { Region } from '../types/lol/summoner.type';
import { CORE_SUMMONER } from '../fragments/partners';

export const GET_CLASH_TEAMS = gql`
  query getClashTeams(
    $region: String!
    $name: String
    $tier: Int
    $from: Int
    $limit: Int
    $orderBy: ClubListOrderType
  ) {
    clashTeams(
      region: $region
      name: $name
      tier: $tier
      from: $from
      limit: $limit
      orderBy: $orderBy
    ) {
      id
      name
      region
      externalUrl
      content
      insertedAt
      userId
      tier
      members {
        position
      }
    }
  }
`;

export const GET_CLASH_TEAM = gql`
  ${CORE_CLASH_TEAM}
  ${CORE_SUMMONER}
  query getClashTeam($id: Int!) {
    clashTeam(id: $id) {
      ...CoreClashTeam
      members {
        userId
        status
        memberType
        isSubscription
        summoner {
          ...CoreSummoner
        }
      }
    }
  }
`;

export const GET_CLASH_TEAM_MEMBERS = gql`
  ${CORE_CLASH_TEAM_MEMBER}
  query getClashTeamMembers($clashId: Int!) {
    clashMembers(clashId: $clashId) {
      ...CoreClashTeamMember
    }
  }
`;

export const COUNT_CLASH_TEAMS = gql`
  query getClashTeamCount {
    countClashs
  }
`;

export const GET_MY_CLASH_TEAMS = gql`
  ${CORE_CLASH_TEAM}
  query getMyClashTeams {
    myClashTeams {
      ...CoreClashTeam
      members {
        userId
        memberType
      }
    }
  }
`;
export interface ClashTeamQueryVariables {
  region: Region;
  name?: string;
  tier?: number;
  from?: number;
  page?: number;
  limit?: number;
  orderBy?: 'MEMBER_COUNT';
}

export function useClashTeams(variables: ClashTeamQueryVariables): {
  clashTeams: ClashTeam[];
  error?: ApolloError;
  loading: boolean;
  fetchMore: () => Promise<void>;
} {
  const {
    data,
    error,
    loading,
    fetchMore: _fetchMore,
  } = useQuery<{
    clashTeams: ClashTeam[];
  }>(GET_CLASH_TEAMS, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const [teams, setTeams] = React.useState<ClashTeam[]>([]);
  const changeSet = useClashTeamUpdated(variables);

  React.useEffect(() => {
    if (!data) return;

    setTeams((prev) => prev.concat(data.clashTeams));
    return () => setTeams([]);
  }, [data]);

  React.useEffect(() => {
    if (!changeSet) return;

    switch (changeSet.action) {
      case FetchActionType.ADDED:
        setTeams((prevEntries) => [changeSet.clash, ...prevEntries]);
        return;

      case FetchActionType.UPDATED: {
        setTeams((prevEntries) => {
          const updatedIndex = prevEntries.findIndex(
            (c) => c.id === changeSet.clash.id
          );
          const entries = JSON.parse(JSON.stringify(prevEntries));
          entries[updatedIndex] = changeSet.clash;
          return entries;
        });
        return;
      }

      case FetchActionType.DELETED:
        setTeams((prevEntries) =>
          prevEntries.filter((c) => c.id !== changeSet.clash.id)
        );
        return;

      default:
        return;
    }
  }, [changeSet]);

  return {
    clashTeams: teams,
    error,
    loading,
    fetchMore,
  };

  async function fetchMore(): Promise<void> {
    const lastItem = last(teams);

    if (!lastItem) return;

    const { data: nextData } = await _fetchMore({
      variables: {
        from: dayjs(lastItem.insertedAt).unix(),
      },
    });

    setTeams((prev) => prev.concat(nextData.clashTeams));
  }
}

export function useClashTeam(id: number) {
  const { data, error, loading, ...rest } = useQuery<{
    clashTeam: ClashTeam;
  }>(GET_CLASH_TEAM, {
    variables: { id },
    skip: !id,
  });

  return {
    clashTeam: data?.clashTeam,
    error,
    loading,
    ...rest,
  };
}

export function useClashTeamMembers(clashId: number): {
  members?: ClashTeamMember[];
  error?: ApolloError;
  loading: boolean;
} {
  const { data, error, loading } = useQuery<{
    clashMembers: ClashTeamMember[];
  }>(GET_CLASH_TEAM_MEMBERS, {
    variables: { clashId },
  });

  return {
    members: data?.clashMembers,
    error,
    loading,
  };
}

export function useCountClashTeams(): { count: number } {
  const { data } = useQuery<{ countClashs: number }>(COUNT_CLASH_TEAMS, {
    notifyOnNetworkStatusChange: true,
  });

  return {
    count: data?.countClashs ?? 0,
  };
}

export function useMyClashTeams(): {
  clashTeams: ClashTeam[];
  isOwned: boolean;
  error?: ApolloError;
  loading: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<{ myClashTeams: ClashTeam[] }>>;
} {
  const { data, error, loading, refetch } = useQuery<{
    myClashTeams: ClashTeam[];
  }>(GET_MY_CLASH_TEAMS, {
    fetchPolicy: 'no-cache',
  });

  const { userMid } = useUser();

  const teams = data?.myClashTeams ?? [];

  const isOwned = React.useMemo(
    () => !!teams.find((team) => team.userId === userMid),
    [teams, userMid]
  );

  return {
    clashTeams: teams,
    isOwned,
    error,
    loading,
    refetch,
  };
}
