import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: React.HTMLAttributes<HTMLImageElement>['className'];
  championId: string;
  championName: string;
};

const ChampionIcon: React.FC<Props> = ({
  className,
  championId,
  championName,
}) => {
  return (
    <img
      width={32}
      height={32}
      className={classNames('rounded-full', className)}
      src={`//opgg-static.akamaized.net/meta/images/lol/latest/champion/${championId}.png?image=c_crop,h_103,w_103,x_9,y_9/q_auto,f_webp,w_64&v=1681967972734`}
      alt={`${championName}`}
    />
  );
};

export default ChampionIcon;
