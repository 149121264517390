import classNames from 'classnames';
import React from 'react';
import { Position } from '../types/lol/partner.type';
import { Nullable } from '../types/UtilTypes';
import { noop } from '../utils/misc';
import PositionIcon from './PositionIcon';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';

interface Props {
  position: Nullable<Position> | undefined;
  className?: string;
  onChange?: (position: Position) => void;
  readonly?: boolean;
}

const PositionSelect: React.FC<Props> = ({
  className,
  position,
  readonly,
  onChange: handleChange = noop,
}) => {
  return (
    <ul className={classNames('filters__position', className)}>
      {Object.values(Position).map((pos: Position) => (
        <li
          key={pos}
          value={pos}
          className={classNames(
            `flex items-center p-2 first:rounded-l last:rounded-r`,
            !readonly && 'cursor-pointer hover:bg-gray-600',
            {
              selected: position === pos,
            }
          )}
          onClick={handlePosition(pos)}
        >
          <PositionIcon
            position={pos}
            readonly={readonly && position !== pos}
          />
        </li>
      ))}
    </ul>
  );

  function handlePosition(position: Position): () => void {
    if (readonly) return noop;
    return () => {
      handleChange(position);
      dataLayer(
        {
          op_event: GTMEventInfoType.CLICK_POSITION_FILTER,
          op_event_value: position,
        },
        GTMEventType.CLICK
      );
    };
  }
};

export default PositionSelect;
