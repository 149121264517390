import gql from 'graphql-tag';
import { CORE_SUMMONER } from './partners';

export const CORE_CLASH_TEAM = gql`
  ${CORE_SUMMONER}
  fragment CoreClashTeam on ClashTeam {
    id
    name
    region
    externalUrl
    content
    insertedAt
    userId
    tier
    members {
      summoner {
        ...CoreSummoner
      }
      position
    }
  }
`;

export const CORE_CLASH_TEAM_MEMBER = gql`
  ${CORE_SUMMONER}
  fragment CoreClashTeamMember on ClashMember {
    id
    clashId
    userId
    memberType
    status
    introduce
    region
    tier
    insertedAt
    position
    isSubscription
    summoner {
      ...CoreSummoner
    }
  }
`;
