import classNames from 'classnames';
import React from 'react';
import { Position } from '../types/lol/partner.type';
import { noop } from '../utils/misc';
import PositionIcon from './PositionIcon';

interface Props {
  positions: Position[];
  className?: string;
  onChange?: (positions: Position[]) => void;
  readonly?: boolean;
}

const RequiredPositionSelect: React.FC<Props> = ({
  className,
  positions,
  readonly,
  onChange: handleChange = noop,
}) => {
  return (
    <ul className={classNames('filters__position', className)}>
      {Object.values(Position).map((pos: Position) => (
        <li
          key={pos}
          value={pos}
          className={classNames(
            `flex items-center p-2 first:rounded-l last:rounded-r`,
            !readonly && 'cursor-pointer hover:bg-gray-600',
            {
              selected: positions?.includes(pos),
            }
          )}
          onClick={handlePosition(pos)}
        >
          <PositionIcon
            position={pos}
            color={'#fff'}
            readonly={positions?.includes(pos)}
          />
        </li>
      ))}
    </ul>
  );

  function handlePosition(position: Position): () => void {
    if (readonly) return noop;
    let posArr = positions;
    if (position === Position.ALL) {
      posArr = positions.includes(position) ? [] : [Position.ALL];
    } else {
      if (positions?.includes(position)) {
        posArr = positions.filter((pos) => pos !== position);
      } else {
        posArr = [...positions, position];
      }
      posArr = posArr.filter((pos) => pos !== Position.ALL);

      if (posArr.length === 5) {
        posArr = [Position.ALL];
      }
    }

    return () => handleChange(posArr);
  }
};

export default RequiredPositionSelect;
